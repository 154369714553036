<script setup>
import config from "../config";
import { onMounted, onUnmounted, computed } from "vue";
import { useRoute } from 'vue-router'
import { useStore } from "vuex";

const store = useStore();
const route = useRoute()

const status = computed({
    get() {
        return store.state.status;
    },
    set(value) {
        store.commit("SET_STATUS", value);
    },
});

const rewards = computed({
    get() {
        return store.state.account_rewards;
    },
    set(value) {
        store.commit("SET_ACCOUNT_REWARDS", value);
    },
});

let intervalId;
onMounted(() => {
    let params = {
        login: route.params.login,
    };
    store.dispatch("fetchAccountRewards", params);
    intervalId = setInterval(() => {
        let params = {
            login: route.params.login,
        };
        store.dispatch("fetchAccountRewards", params);
    }, 10000);
});
onUnmounted(() => clearInterval(intervalId));
</script>
<template>
    <v-row dense>
        <v-col cols="12" xs="12" sm="12" md="8" lg="8" xl="8">
            <v-table density="compact" class="table-border rounded">
                <thead>
                    <tr>
                        <th class="text-center" style="width: 20%;">{{ $t("period") }}</th>
                        <th class="text-center" style="width: 20%;">{{ $t("blocks") }}</th>
                        <th class="text-center" style="width: 20%;">{{ $t("personal_effort") }}</th>
                        <th class="text-center" style="width: 20%;">{{ config.coin }}</th>
                        <th class="text-center" style="width: 20%;">USD</th>
                    </tr>
                </thead>
                <tbody v-if="rewards.rewards.length > 0">
                    <tr v-for="(item, index) in rewards.sumrewards" :key="index">
                        <td class="text-center">
                            {{ $t(item.name) }}
                        </td>
                        <td class="text-center">{{ item.blocks }}</td>
                        <td class="text-center">
                            <span v-if="item.effort >= 1.0" class="text-blue">
                                {{ $filters.effort(item.effort, 0) }}
                            </span>
                            <span v-else class="text-green">
                                {{ $filters.effort(item.effort, 0) }}
                            </span>
                        </td>
                        <td class="text-center">
                            {{ $filters.reward(item.reward) }}
                        </td>
                        <td class="text-center">
                            {{ $filters.price(item.usd) }}
                        </td>
                    </tr>
                </tbody>
                <tbody v-else>
                    <tr>
                        <td colspan="5" class="text-center">
                            {{ $t("no_data") }}
                        </td>
                    </tr>
                </tbody>
            </v-table>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
            <v-table density="compact" class="table-border rounded">
                <thead>
                    <tr>
                        <th class="text-center" style="width: 50%;">{{ $t("blocks") }}</th>
                        <th class="text-center" style="width: 50%;">{{ $t("personal_effort") }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, index) in rewards.luck" :key="index">
                        <td class="text-center">
                            {{ index }}
                        </td>
                        <td class="text-center">
                            <span v-if="item.effort >= 1.0" class="text-blue">
                                {{ $filters.effort(item.effort, 0) }}
                            </span>
                            <span v-else class="text-green">
                                {{ $filters.effort(item.effort, 0) }}
                            </span>
                        </td>
                    </tr>
                    <tr v-if="Object.keys(rewards.luck).length == 0">
                        <td colspan="2" class="text-center">
                            {{ $t("no_data") }}
                        </td>
                    </tr>
                </tbody>
            </v-table>
        </v-col>
    </v-row>
    <v-table density="comfortable" class="border rounded mt-4">
        <thead>
            <tr>
                <th>{{ $t("height") }}</th>
                <th>{{ $t("type") }}</th>
                <th>{{ $t("time") }}</th>
                <th>{{ $t("worker") }}</th>
                <th>{{ $t("personal_effort") }}</th>
                <th>{{ $t("solution") }}</th>
                <th>{{ $t("status") }}</th>
                <th>{{ $t("reward") }}</th>
            </tr>
        </thead>
        <tbody v-if="rewards.rewards.length > 0">
            <tr v-for="(item, index) in rewards.rewards" :key="index">
                <td>
                    <a :href="config.explorer.hash + item.hash" target="_blank" rel="noopener noreferrer"
                        class="text-monospace">
                        {{ item.height }}
                    </a>
                </td>
                <td class="text-capitalize">
                    {{ item.type }}
                </td>
                <td>
                    {{ $filters.time(item.timestamp) }}
                </td>
                <td>
                    <v-code tag="span">
                        {{ item.worker }}
                    </v-code>
                </td>
                <td>
                    <span v-if="item.effort >= 1.0" class="text-blue">
                        {{ $filters.effort(item.effort, 0) }}
                    </span>
                    <span v-else class="text-green">
                        {{ $filters.effort(item.effort, 0) }}
                    </span>
                </td>
                <td>
                    <span v-html="$filters.difficulty(item.shareDiff, 3)"></span>
                </td>
                <td class="text-capitalize">
                    <span v-if="item.immature"> immature </span>
                    <span v-else> confirmed </span>
                </td>
                <td :class="item.immature ? 'text-grey' : ''">
                    {{ $filters.reward(item.reward) }}
                </td>
            </tr>
        </tbody>
        <tbody v-else>
            <tr>
                <td colspan="8" class="text-center">
                    {{ $t("no_data") }}
                </td>
            </tr>
        </tbody>
    </v-table>
</template>