export default {
    name: "eCash",
    coin: "XEC",
    scheme: "SOLO",
    account: 'qrtmcfv2r6p7t63v30ta4wwpkm4vxh399qtu9gkzrh',
    url: 'https://e.cash/',
    explorer_url: 'https://explorer.bitcoinabc.org',
    coingecko: 'https://www.coingecko.com/en/coins/ecash',
    explorer: {
        address: 'https://explorer.bitcoinabc.org/address/',
        height: 'https://explorer.bitcoinabc.org/block-height/',
        hash: 'https://explorer.bitcoinabc.org/block/',
        tx: 'https://explorer.bitcoinabc.org/tx/',
    },
    ports: [
        {
            info: 'LOW',
            host: 'molepool.com',
            port: 9601,
            diff: 250000,
        },
        {
            info: 'MID',
            host: 'molepool.com',
            port: 9602,
            diff: 1000000,
        },
        {
            info: 'HIGH',
            host: 'molepool.com',
            port: 9603,
            diff: 10000000,
        },
    ]
};