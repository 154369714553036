<script setup>
import config from "../config";
import { onMounted, onUnmounted, computed } from "vue";
import { useStore } from "vuex";
const store = useStore();

const status = computed({
  get() {
    return store.state.status;
  },
  set(value) {
    store.commit("SET_STATUS", value);
  },
});

const blocks = computed({
  get() {
    return store.state.blocks;
  },
  set(value) {
    store.commit("SET_BLOCKS", value);
  },
});

let intervalId;
onMounted(() => {
  store.dispatch("fetchBlocks");
  intervalId = setInterval(() => {
    store.dispatch("fetchBlocks");
  }, 10000);
});
onUnmounted(() => clearInterval(intervalId));
</script>
<template>
  <h3 class="mb-2">{{ $t("blocks_stats") }}</h3>
  <v-table density="compact" class="table-border rounded">
    <thead>
      <tr>
        <th class="text-center" style="width: 25%">
          {{ $t("blocks") }}
        </th>
        <th class="text-center" style="width: 25%">
          {{ $t("effort") }}
        </th>
        <th class="text-center" style="width: 25%">Orphan</th>
      </tr>
    </thead>
    <tbody v-if="status === 'success'">
      <tr v-for="(item, index) in blocks.luck" :key="index">
        <td class="text-center">{{ index }}</td>
        <td class="text-center">
          <span v-if="item.effort >= 1.0" class="text-blue">
            {{ $filters.effort(item.effort, 0) }}
          </span>
          <span v-else class="text-green">
            {{ $filters.effort(item.effort, 0) }}
          </span>
        </td>
        <td class="text-center text-red">
          {{ $filters.effort(item.orphan, 0) }}
        </td>
      </tr>
    </tbody>
    <tbody v-else>
      <tr v-for="i in 4" :key="i">
        <td v-for="i in 3" :key="i">
          <v-skeleton-loader pill height="24" />
        </td>
      </tr>
    </tbody>
  </v-table>

  <span v-if="status === 'success' && blocks.candidate.length > 0">
    <h4 class="mb-2 mt-4">
      {{ $t("blocks_news") }}
    </h4>
    <v-table density="comfortable" class="border rounded">
      <thead>
        <tr>
          <th>{{ $t("height") }}</th>
          <th>{{ $t("type") }}</th>
          <th>{{ $t("time") }}</th>
          <th>{{ $t("server") }}</th>
          <th>{{ $t("miner") }}</th>
          <th>{{ $t("effort") }}</th>
          <th>{{ $t("solution") }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in blocks.candidate" :key="index">
          <td>
            <a :href="config.explorer.hash + item.hash" target="_blank" rel="noopener noreferrer" class="text-monospace">
              {{ item.height }}
            </a>
          </td>
          <td class="text-capitalize">
            {{ item.type }}
          </td>
          <td>
            {{ $filters.time(item.timestamp) }}
          </td>
          <td class="text-uppercase">
            {{ item.server }}
          </td>
          <td class="text-monospace">
            <router-link class="text-monospace" :to="'/account/' + item.miner">
              {{ item.miner }}
            </router-link>
          </td>
          <td>
            <span v-if="item.effort >= 1.0" class="text-blue">
              {{ $filters.effort(item.effort, 0) }}
            </span>
            <span v-else class="text-green">
              {{ $filters.effort(item.effort, 0) }}
            </span>
          </td>
          <td>
            <span v-html="$filters.difficulty(item.shareDiff, 3)"></span>
          </td>
        </tr>
      </tbody>
    </v-table>
  </span>

  <h4 class="mb-2 mt-4">
    {{ $t("blocks_stats_confarm", { msg: 101 }) }}
  </h4>
  <v-table density="comfortable" class="border rounded">
    <thead>
      <tr>
        <th>{{ $t("height") }}</th>
        <th>{{ $t("type") }}</th>
        <th>{{ $t("time") }}</th>
        <th>{{ $t("server") }}</th>
        <th>{{ $t("miner") }}</th>
        <th>{{ $t("effort") }}</th>
        <th>{{ $t("solution") }}</th>
        <th>{{ $t("status") }}</th>
        <th>{{ $t("reward") }}</th>
      </tr>
    </thead>
    <tbody v-if="status === 'success' && blocks.blocks.length > 0">
      <tr v-for="(item, index) in blocks.blocks" :key="index">
        <td>
          <a :href="config.explorer.hash + item.hash" target="_blank" rel="noopener noreferrer" class="text-monospace">
            {{ item.height }}
          </a>
        </td>
        <td class="text-capitalize">
          {{ item.type }}
        </td>
        <td>
          {{ $filters.time(item.timestamp) }}
        </td>
        <td class="text-uppercase">
          {{ item.server }}
        </td>
        <td class="text-monospace">
          <router-link class="text-monospace" :to="'/account/' + item.miner">
            {{ item.miner }}
          </router-link>
        </td>
        <td>
          <span v-if="item.effort >= 1.0" class="text-blue">
            {{ $filters.effort(item.effort, 0) }}
          </span>
          <span v-else class="text-green">
            {{ $filters.effort(item.effort, 0) }}
          </span>
        </td>
        <td>
          <span v-html="$filters.difficulty(item.shareDiff, 3)"></span>
        </td>
        <td class="text-capitalize">
          <v-progress-linear v-if="item.status == 'immature'" color="rgba(255,255,255,.1)"
            :model-value="item.confirm * 100" height="18" class="rounded">
            <template v-slot:default="{ value }">
              <small>{{ Math.ceil(value) }}%</small>
            </template>
          </v-progress-linear>
          <span v-else> {{ item.status }}</span>
        </td>
        <td>
          <span v-if="item.status == 'orphaned'" class="text-red">
            {{ (0).toFixed(6) }}
          </span>
          <span v-else :class="item.status == 'immature' ? 'text-grey' : ''">
            {{ $filters.reward(item.reward) }}
          </span>
        </td>
      </tr>
    </tbody>
    <tbody v-else>
      <tr>
        <td colspan="9" class="text-center">
          {{ $t("no_data") }}
        </td>
      </tr>
    </tbody>
  </v-table>
</template>
