<script setup>
import config from "../config";
import { ref } from "vue";
import { mdiInformationVariantCircleOutline, mdiContentCopy, mdiCheckCircleOutline } from '@mdi/js'

let tab = ref('eu');
let snackbar = ref(false);

function success() {
    snackbar.value = true;
}
</script>
<template>
    <v-snackbar v-model="snackbar" location="top" color="success" min-width="150" min-height="30" :timeout="2000"
        class="pa-0">
        <v-icon left size="22" :icon="mdiCheckCircleOutline"></v-icon>
        {{ $t("copy_successful") }}
    </v-snackbar>
    <v-row dense>
        <v-col cols="12">
            <v-card class="card" variant="outlined">
                <v-card-title>
                    {{ $t("сonnection_servers") }} TCP
                </v-card-title>
                <v-divider class="dashed"></v-divider>
                <v-row no-gutters>
                    <v-col>
                        <div class="pa-2">
                            <v-tabs hide-slider show-arrows="" :mobile-breakpoint="0" v-model="tab" class="">
                                <v-tab value="eu">
                                    <v-icon start size="21">
                                        <img src="../assets/locale/eu.svg" alt="" />
                                    </v-icon>
                                    Europe
                                </v-tab>
                                <v-tab value="ru">
                                    <v-icon start size="21">
                                        <img src="../assets/locale/ru.svg" alt="" />
                                    </v-icon>
                                    Russia
                                </v-tab>
                                <!--v-tab value="us" disabled>
                                    <v-icon start size="21">
                                        <img src="../assets/locale/us.svg" alt="" />
                                    </v-icon>
                                    USA
                                </v-tab-->
                            </v-tabs>

                            <v-table density="comfortable" class="rounded mt-2">
                                <thead>
                                    <tr>
                                        <th class="text-left" style="width: 33%;">
                                            {{ $t("diff") }}
                                        </th>
                                        <th class="text-left" style="width: 33%;">
                                            {{ $t("server") }}
                                        </th>
                                        <th class="text-left hidden-sm-and-down" style="width: 33%;">
                                            {{ $t("description") }}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(item, index) in config.ports" :key="index">
                                        <td>
                                            <v-code tag="span">
                                                {{ item.diff }}
                                            </v-code>
                                            (<span v-html="$filters.difficulty(item.diff, 0)"></span>)
                                        </td>
                                        <td>
                                            <v-code tag="span">
                                                {{ tab }}.{{ item.host }}:{{ item.port }}
                                            </v-code>
                                            <span v-if="tab == 'eu'">
                                                <v-btn variant="text" size="x-small" :ripple="false" icon
                                                    v-clipboard="`eu.${item.host}:${item.port}`"
                                                    v-clipboard:success="success">
                                                    <v-icon size="14" :icon="mdiContentCopy"></v-icon>
                                                </v-btn>
                                            </span>
                                            <span v-else-if="tab == 'ru'">
                                                <v-btn variant="text" size="x-small" :ripple="false" icon
                                                    v-clipboard="`ru.${item.host}:${item.port}`"
                                                    v-clipboard:success="success">
                                                    <v-icon size="14" :icon="mdiContentCopy"></v-icon>
                                                </v-btn>
                                            </span>
                                        </td>
                                        <td class="hidden-sm-and-down">
                                            {{ $t(item.info) }}
                                        </td>
                                    </tr>
                                </tbody>
                            </v-table>
                            <v-divider></v-divider>
                            <v-alert class="mt-2" type="info" variant="tonal" density="compact"
                                :icon="mdiInformationVariantCircleOutline">
                                {{ $t("configuration_password") }}.
                                {{ $t("example") }}:
                                <v-code tag="span">
                                    d={{ config.ports.length != 0 ? (config.ports[0].diff / 10) : "" }}
                                </v-code>
                            </v-alert>
                        </div>
                    </v-col>
                </v-row>
            </v-card>
        </v-col>
        <v-col cols="12">
            <v-card class="card" variant="outlined">
                <v-card-title>
                    {{ $t("configuration") }}
                </v-card-title>
                <v-divider class="dashed"></v-divider>
                <v-row no-gutters>
                    <v-col>
                        <div class="pa-2">
                            <p>{{ $t("configuration_info") }}</p>
                            <dl class="v-row mt-3">
                                <dt class="v-col-sm-2 text-monospace">
                                    <v-code tag="span">&lt;{{ $t("your_address") }}&gt;</v-code>
                                </dt>
                                <dd class="v-col-sm-10">
                                    {{ $t("configuration_wallet", { msg: config.name }) }} <br />
                                    {{ $t("example") }}:
                                    <v-code tag="span">{{ config.account }}</v-code>
                                </dd>
                            </dl>
                            <dl class="v-row">
                                <dt class="v-col-sm-2">
                                    <v-code tag="span">&lt;{{ $t("worker_name") }}&gt;</v-code>
                                </dt>
                                <dd class="v-col-sm-10">
                                    {{ $t("configuration_worker") }} <br />
                                    {{ $t("example") }}: <v-code tag="span">Rig1</v-code>
                                </dd>
                            </dl>
                            <dl class="v-row">
                                <dt class="v-col-sm-2">
                                    <v-code tag="span">&lt;{{ $t("password") }}&gt;</v-code>
                                </dt>
                                <dd class="v-col-sm-10">
                                    {{ $t("configuration_password_zero") }} <br />
                                </dd>
                            </dl>
                            <v-divider class="my-4"></v-divider>
                            <div>
                                <small>URL: </small>
                                <v-code tag="span">
                                    stratum+tcp://{{ tab }}.{{ config.ports.length != 0 ? (config.ports[0].host + ":" +
                                        config.ports[0].port) : "" }}
                                </v-code>
                            </div>
                            <div class="mt-2">
                                <small>Worker: </small>
                                <v-code tag="span">&lt;{{ $t("your_address") }}&gt;.&lt;{{ $t("worker_name") }}&gt;</v-code>
                            </div>
                            <div class="mt-2">
                                <small>Password: </small>
                                <v-code tag="span">&lt;{{ $t("password") }}&gt;</v-code>
                            </div>
                        </div>
                    </v-col>
                </v-row>
            </v-card>
        </v-col>
        <v-col cols="12">
            <v-card class="card" variant="outlined">
                <v-card-title>
                    {{ $t("monitoring") }}
                </v-card-title>
                <v-divider class="dashed"></v-divider>
                <p class="pa-4" v-html="$t('telegram_bot', { msg: 'https://t.me/MolepoolSoloBot' })"></p>
            </v-card>
        </v-col>
    </v-row>
</template>